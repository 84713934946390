import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputMask from 'react-input-mask';
import axios from 'axios';
import ItemClient from './itemClient';
import ItemClientAdmin from './itemClientAdmin';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, boat, solicitation, availability) {
    return { client, boat, solicitation, availability };
}
  
const rows = [
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00')
    
];

function Clients() {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [typeUser, setTypeUser] = useState(localStorage.getItem('typeUser'));

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [cellphone, setCellphone] = useState();
    const [rg, setRg] = useState();
    const [cpf, setCpf] = useState();
    const [birthDate, setBirthDate] = useState();
    const [cep, setCep] = useState();
    const [address, setAddress] = useState();
    const [number, setNumber] = useState();
    const [neighborhood, setNeighborhood] = useState();
    const [complement, setComplement] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();

    const [loading, setLoading] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleCreateUser = () => {

        if(!name || !email || !cellphone || !rg || !cpf || !birthDate || !cep || !address || !number || !neighborhood || !city || !state){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/clients/new-client', qs.stringify({
                name: name,
                email: email,
                cellphone: cellphone,
                rg: rg,
                cpf: cpf,
                birthDate: birthDate,
                cep: cep,
                address: address,
                number: number,
                neighborhood: neighborhood,
                complement: complement,
                city: city,
                state: state,
                note: note
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);

                setName('');
                setEmail('');
                setCellphone('');
                setRg('');
                setCep('');
                setAddress('');
                setNumber('');
                setNeighborhood('');
                setComplement('');
                setCity('');
                setState('');

                setOpen(false);

                handleGetClients();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar o cliente!');
                setOpenSnackbar(true);

            })

        }

    }

    const [clients, setClients] = useState([]);
    const [note, setNote] = useState()

    const handleGetClients = (marina) => {

        setLoading(true);

        let marinaSearch = "";

        if(marina){
            marinaSearch = marina;
        }

        API.get('/clients?marina='+marinaSearch, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoading(false);
            setClients(res.data);

        })
        .catch(err => {

            setLoading(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar clientes!');
            setOpenSnackbar(true);

        })

    }

    useEffect(() => {

        handleGetClients();
        handlegetMarinas();

    }, [0])

    const [marinas, setMarinas] = useState([]);

    const handlegetMarinas = () => {

        API.get('/marinas', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setMarinas(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar marinas!');
            setOpenSnackbar(true);

        })

    }

    const handleGetEnderecoByCep = () => {

        axios.get('https://viacep.com.br/ws/'+cep+'/json/',{
            params: {},
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setAddress(res.data.logradouro);
            setNeighborhood(res.data.bairro);
            setCity(res.data.localidade);
            setState(res.data.uf);
        })
        .catch(res => {})

    }

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                {typeUser && typeUser === "superadmin" ?
                    <>

                        <Grid container spacing={3}>
                            <Grid item md={10} xs={12}></Grid>
                            <Grid item md={2} xs={12}>
                                <TextField 
                                    variant="outlined" 
                                    label="Marina" 
                                    fullWidth 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => {
                                        handleGetClients(e.target.value)
                                    }}
                                >
                                    <option value="">Todas</option>
                                    {marinas.map((marina) => (
                                        <option value={marina.id}>{marina.name}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <caption>Clientes</caption>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nome</TableCell>
                                                <TableCell align="left">E-mail</TableCell>
                                                <TableCell align="left">Contato</TableCell>
                                                <TableCell align="left">Qtd. embarcações</TableCell>
                                                <TableCell align="left">Marina</TableCell>
                                                {/*<TableCell align="left">Mensalidades</TableCell>*/}
                                                <TableCell align="left">Cadastrado em</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {clients.map((client) => (
                                                <ItemClientAdmin handleGetClients={handleGetClients} client={client} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </>
                :
                    <>

                        <Grid container spacing={3}>
                            <Grid item md={10} xs={12}></Grid>
                            <Grid item md={2} xs={12}>
                                <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Criar cliente</Button>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <caption>Clientes</caption>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nome</TableCell>
                                                <TableCell align="left">E-mail</TableCell>
                                                <TableCell align="left">Contato</TableCell>
                                                <TableCell align="left">Qtd. embarcações</TableCell>
                                                <TableCell align="left">Marina</TableCell>
                                                {/*<TableCell align="left">Mensalidades</TableCell>*/}
                                                <TableCell align="left">Cadastrado em</TableCell>
                                                <TableCell align="left"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {clients.map((client) => (
                                                <ItemClient handleGetClients={handleGetClients} client={client} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </>
                }
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Cadastro de clientes</Typography>

                            <br></br>

                            <Typography variant="h6" style={{color: '#707072'}}>Dados</Typography>
                            
                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField value={name} onChange={(e) => {setName(e.target.value)}} variant="outlined" label="Nome completo" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} variant="outlined" label="E-mail" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        value={cellphone} 
                                        onChange={(e) => {setCellphone(e.target.value)}}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField variant="outlined" label="Celular" fullWidth />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField value={rg} onChange={(e) => {setRg(e.target.value)}} variant="outlined" label="RG" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={cpf} 
                                        onChange={(e) => {setCpf(e.target.value)}}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField variant="outlined" label="CPF" fullWidth />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <InputMask
                                        mask="99/99/9999"
                                        value={birthDate} 
                                        onChange={(e) => {setBirthDate(e.target.value)}}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField variant="outlined" label="Nascimento" fullWidth />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <InputMask
                                        mask="99999-999"
                                        value={cep} 
                                        onChange={(e) => {setCep(e.target.value)}}
                                        onBlur={handleGetEnderecoByCep}
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField variant="outlined" label="CEP" fullWidth />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <TextField InputLabelProps={{shrink: address ? true : false}} value={address} onChange={(e) => {setAddress(e.target.value)}} variant="outlined" label="Endereço" fullWidth />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <InputMask
                                        mask="99999999"
                                        value={number} 
                                        onChange={(e) => {setNumber(e.target.value)}} 
                                        maskChar=""
                                    >
                                        {() => 
                                            <TextField variant="outlined" label="Número" fullWidth />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField InputLabelProps={{shrink: neighborhood ? true : false}} value={neighborhood} onChange={(e) => {setNeighborhood(e.target.value)}} variant="outlined" label="Bairro" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField value={complement} onChange={(e) => {setComplement(e.target.value)}} variant="outlined" label="Complemento" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField InputLabelProps={{shrink: city ? true : false}} value={city} onChange={(e) => {setCity(e.target.value)}} variant="outlined" label="Cidade" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField InputLabelProps={{shrink: state ? true : false}} value={state} onChange={(e) => {setState(e.target.value)}} variant="outlined" label="Estado" fullWidth />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField multiline rows={4} InputLabelProps={{shrink: note ? true : false}} value={note} onChange={(e) => {setNote(e.target.value)}} variant="outlined" label="Observação" fullWidth />
                                </Grid>
                            </Grid>

                            {/*<br></br>

                            <Typography variant="h6" style={{color: '#707072'}}>Barcos</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Categoria" 
                                        fullWidth 
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        size="large"
                                    >
                                        <option value=""></option>
                                        <option value="marina_1">Categoria 1</option>
                                        <option value="marina_2">Categoria 2</option>
                                        <option value="marina_3">Categoria 3</option>
                                    </TextField>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                    <TextField variant="outlined" label="Descrição do barco" fullWidth />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1" style={{color: '#3282A5', marginTop: '0px'}}>Adicionar barco</Typography>
                                </Grid>
                            </Grid>*/}

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={handleCreateUser} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                        {loadingNew ? 
                                            <CircularProgress size={24} style={{color: '#FFF'}} />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </div>

        </div>
    );

}

export default Clients;