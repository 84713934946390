import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

const ItemService = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [description, setDescription] = useState(props.service.description);
    const [value, setValue] = useState(props.service.value);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleUpdateService = () => {

        setLoadingUpdate(true);

        API.patch('/services/update-service/'+props.service.id, qs.stringify({
            description: description,
            value: value
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoadingUpdate(false);
            setOpen(false);
            props.handleGetServices();

        })
        .catch(err => {

            setLoadingUpdate(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao salvar cliente!');
            setOpenSnackbar(true);

        })

    }

    return (
        <>

            <TableRow key={props.service.index}>
                <TableCell component="th" scope="props.client">
                    {props.service.description}
                </TableCell>
                <TableCell align="left">{parseFloat(props.service.value).toLocaleString('pt-BR',{style: 'currency', currency:'BRL'})}</TableCell>
                <TableCell align="right">
                    <Tooltip onClick={() => {setOpen(true)}} title="Editar" size="small">
                        <IconButton aria-label="editar">
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
    
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                        <Typography variant="h5">Editar serviço</Typography>

                        <br></br>

                        <Typography variant="h6" style={{color: '#707072'}}>Dados</Typography>
                        
                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={9} xs={12}>
                                <TextField value={description} onChange={(e) => {setDescription(e.target.value)}} variant="outlined" label="Descrição" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField value={value} onChange={(e) => {setValue(e.target.value)}} variant="outlined" label="Valor" fullWidth type="number" />
                            </Grid>
                        </Grid>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Button onClick={handleUpdateService} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                    {loadingUpdate ? 
                                        <CircularProgress size={24} style={{color: '#FFF'}} />
                                    :
                                        'Salvar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemService;