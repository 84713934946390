import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputMask from 'react-input-mask';
import axios from 'axios';
import ItemSubcategory from './ItemSubcategory';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, boat, solicitation, availability) {
    return { client, boat, solicitation, availability };
}
  
const rows = [
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00')
    
];

const Subcategories = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleCreateSubCategory = () => {

        if(!descriptionSubcategory){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/settings/new-subcategory', qs.stringify({
                category: props.match.params.id,
                name: descriptionSubcategory
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);
                setDescriptionSubcategory('');
                setOpen(false);
                handleGetSubcategories();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar a subcategoria!');
                setOpenSnackbar(true);

            })

        }

    }

    const [nameCategory, setNameCategory] = useState();
    const [subcategories, setSubcategories] = useState([])

    useEffect(() => {

        handleGetCategory();
        handleGetSubcategories();

    }, [0])

    const handleGetSubcategories = () => {

        API.get('/settings/subcategories/' + props.match.params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setSubcategories(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar as subcategorias!');
            setOpenSnackbar(true);

        })

    }

    const handleGetCategory = () => {

        API.get('/settings/category-by-id/' + props.match.params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setNameCategory(res.data.name);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar detalhes da categoria!');
            setOpenSnackbar(true);

        })

    }

    const [descriptionSubcategory, setDescriptionSubcategory] = useState();

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Grid container spacing={3} style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item md={10} xs={12}>
                        <Typography variant="h5">Categoria: {nameCategory}</Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Atribuir subcategoria</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table">
                                <caption>Subcategorias</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Descrição</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subcategories.map((subcategory) => (
                                        <ItemSubcategory handleGetSubcategories={handleGetSubcategories} subcategory={subcategory} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Cadastro da subcategoria</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField value={descriptionSubcategory} onChange={(e) => {setDescriptionSubcategory(e.target.value)}} variant="outlined" label="Descrição da subcategoria" fullWidth />
                                </Grid>
                            </Grid>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={handleCreateSubCategory} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                        {loadingNew ? 
                                            <CircularProgress size={24} style={{color: '#FFF'}} />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </div>

        </div>
    );

}

export default Subcategories;