import React, { useEffect, useState } from 'react';
import API from '../../api';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Container, TextField } from '@material-ui/core';
import moment from 'moment';
import Dash from '../../components/dash';
import ItemSolicitation from './ItemSolicitation';
import ItemOrder from './ItemOrder';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    paper: {
        padding: '20px'
    },
    table: {
        minWidth: 650
    },
    indicator: {
        backgroundColor: '#3282A5',
    }
}));

function createData(client, boat, solicitation, availability) {
    return { client, boat, solicitation, availability };
}
  
const rows = [
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', 'Lancha Nomar 490 Sport', '10/07/2021 às 20:00', '10/07/2021 às 21:00'),
    
];

function Home() {

    const classes = useStyles();
    const theme = useTheme();

    const [typeUser, setTypeUser] = useState(localStorage.getItem('typeUser'));

    const [amountMarinas, setAmountMarinas] = useState(0);
    const [amountClients, setAmountClients] = useState(0);
    const [solicitations, setSolicitations] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    useEffect(() => {

        handleGetDash();
        handleGetStatus();

    }, [0])

    const handleGetDash = () => {

        setLoading(true);

        API.get('/dash', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoading(false);
            setAmountMarinas(res.data.marinas);
            setAmountClients(res.data.clients);
            setSolicitations(res.data.solicitations);
            setOrders(res.data.orders);

        })
        .catch(err => {

            setLoading(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar dados!');
            setOpenSnackbar(true);

        })

    }

    const [statuss, setStatuss] = useState([])

    const handleGetStatus = () => {

        API.get('/settings/status', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setStatuss(res.data);

        })
        .catch(err => {})

    }

    const [section, setSection] = useState(0)

    const handleChange = (event, newValue) => {
        setSection(newValue);
    };

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>
                
                <Grid container spacing={3}>
                    <Grid item md={typeUser && typeUser === "superadmin" ? 3 : 4} xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6">Qtd. solicitações</Typography>
                            <br></br>
                            <Typography variant="h3">{solicitations.length}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item md={typeUser && typeUser === "superadmin" ? 3 : 4} xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6">Qtd. pedidos</Typography>
                            <br></br>
                            <Typography variant="h3">{orders.length}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item md={typeUser && typeUser === "superadmin" ? 3 : 4} xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6">Qtd. clientes</Typography>
                            <br></br>
                            <Typography variant="h3">{amountClients}</Typography>
                        </Paper>
                    </Grid>
                    {typeUser && typeUser === "superadmin" && (
                        <Grid item md={typeUser && typeUser === "superadmin" ? 3 : 4} xs={12}>
                            <Paper className={classes.paper} elevation={1}>
                                <Typography variant="h6">Qtd. marinas</Typography>
                                <br></br>
                                <Typography variant="h3">{amountMarinas}</Typography>
                            </Paper>
                        </Grid>
                    )}

                    <Grid item md={12} xs={12} style={{paddingBottom: '0px'}}>
                        <AppBar position="static" style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
                            <Tabs value={section} onChange={handleChange} aria-label="simple tabs example" classes={{ indicator: classes.indicator }}>
                                <Tab label="Solicitações" {...a11yProps(0)} style={{textTransform: 'none', color: '#000'}} />
                                <Tab label="Pedidos" {...a11yProps(1)} style={{textTransform: 'none', color: '#000'}} />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    {section == 0 && (

                        <Grid item md={12} xs={12}>
                            <TabPanel value={section} index={0}>
                                    
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <caption>Solicitações</caption>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Cliente</TableCell>
                                                <TableCell align="left">Barco</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Solicitado em</TableCell>
                                                <TableCell align="left">Atualizado em</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {solicitations.map((solicitation) => (
                                            <ItemSolicitation handleGetSolicitations={handleGetDash} statuss={statuss} key={solicitation.index} solicitation={solicitation} />
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </TabPanel>
                        </Grid>
                    
                    )}

                    {section == 1 && (
                    
                        <Grid item md={12} xs={12}>
                            <TabPanel value={section} index={1}>
                                    
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <caption>Pedidos</caption>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Cliente</TableCell>
                                                <TableCell align="left">Valor</TableCell>
                                                {typeUser && typeUser === "superadmin" && ( <TableCell align="left">Marina</TableCell> )}
                                                <TableCell align="left">Solicitado em</TableCell>
                                                <TableCell align="left">Atualizado em</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {orders.map((order) => (
                                            <ItemOrder typeUser={typeUser} handleGetOrders={handleGetDash} key={order.index} order={order} />
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </TabPanel>
                        </Grid>
                    
                    )}

                </Grid>
                
                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
        
            </div>

        </div>
    );

}

export default Home;