import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import qs from 'qs';
import ItemMarina from './itemMarina';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(name, city, state) {
    return { name, city, state };
}
  
const rows = [
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo'),
    createData('Marina do Leste 1', 'Rifaina', 'São Paulo')
];

function Marinas() {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [marinas, setMarinas] = useState([]);

    useEffect(() => {

        handleGetMarinas();

    }, [0])

    const handleGetMarinas = () => {

        setLoading(true);

        API.get('/marinas', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoading(false);
            setMarinas(res.data);

        })
        .catch(err => {

            setLoading(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar marinas!');
            setOpenSnackbar(true);

        })

    }

    const handleNewMarina = () => {

        if(!name || !email || !city || !state){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/marinas/new-marina', qs.stringify({
                name: name,
                email: email,
                city: city,
                state: state
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);
                setName('');
                setEmail('');
                setCity('');
                setState('');
                setOpen(false);
                handleGetMarinas();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar marina!');
                setOpenSnackbar(true);

            })

        }

    }

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Grid container spacing={3}>
                    <Grid item md={10} xs={12}></Grid>
                    <Grid item md={2} xs={12}>
                        <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Nova marina</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table">
                                <caption>Marinas</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="left">Cidade</TableCell>
                                        <TableCell align="left">Estado</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {marinas.map((marina) => (
                                        <ItemMarina handleGetMarinas={handleGetMarinas} marina={marina} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Inserir marina</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField value={name} onChange={(e) => {setName(e.target.value)}} variant="outlined" label="Nome" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} variant="outlined" label="E-mail" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField value={city} onChange={(e) => {setCity(e.target.value)}} variant="outlined" label="Cidade" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField value={state} onChange={(e) => {setState(e.target.value)}} variant="outlined" label="Estado" fullWidth />
                                </Grid>
                            </Grid>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={handleNewMarina} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                        {loadingNew ? 
                                            <CircularProgress size={24} style={{color: '#FFF'}} />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
        
            </div>

        </div>
    );

}

export default Marinas;