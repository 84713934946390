import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import Dash from '../components/dash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, value, date) {
    return { client, value, date };
}
  
const rows = [
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00'),
    createData('Fulano de tal', 'R$ 100,00', '10/01/2021 às 19:00')
];

function Requests() {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Grid container spacing={3}>
                    <Grid item md={10} xs={12}></Grid>
                    <Grid item md={2} xs={12}>
                        <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Novo pedido</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table">
                                <caption>Pedidos</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell align="left">Valor total</TableCell>
                                        <TableCell align="left">Data</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.index}>
                                        <TableCell component="th" scope="row">
                                            {row.client}
                                        </TableCell>
                                        <TableCell align="left">{row.value}</TableCell>
                                        <TableCell align="left">{row.date}</TableCell>
                                        <TableCell align="left">
                                            <span style={{color: '#3282A5', cursor: 'pointer'}}>Ver detalhes</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Inserir pedido</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Cliente" 
                                        fullWidth 
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        <option value=""></option>
                                        <option value="1">Fulano de tal</option>
                                        <option value="2">Fulano de tal 2</option>
                                    </TextField>
                                </Grid>
                            </Grid>

                            <br></br>

                            <Typography variant="h6" style={{color: '#707072'}}>Produtos <span style={{color: '#3282A5', cursor: 'pointer', fontSize: '16px'}}>adicionar</span></Typography>

                            <Grid container spacing={3} style={{marginTop: '0px'}}>
                                <Grid item md={7} xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} variant="outlined" label="Descrição" value="Produto 1" disabled fullWidth />
                                </Grid>
                                <Grid item md={1} xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} variant="outlined" label="Qtd" value="1" fullWidth />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} variant="outlined" label="Valor unitário" value="R$ 250,00" disabled fullWidth />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} variant="outlined" label="Valor total" value="R$ 250,00" disabled fullWidth />
                                </Grid>
                            </Grid>

                            <br></br>
                            <br></br>
                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} value="R$ 1.000,00" variant="outlined" label="Valor total" disabled fullWidth />
                                    <br></br>
                                    <br></br>
                                    <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>Salvar</Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>
        
            </div>

        </div>
    );

}

export default Requests;