import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from './pages/login';
import Home from './pages/home/home';
import Clients from './pages/clients/clients';
import Vessels from './pages/vessels/vessels';
import Subcategories from './pages/subcategories/subcategories';
import Products from './pages/products/products';
import Services from './pages/services/services';
import Solicitations from './pages/solicitations';
import Marinas from './pages/marinas/marinas';
import Settings from './pages/settings/settings';
import Requests from './pages/requests';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <Route path="/home" exact={true} component={Home} />
      <Route path="/clients" exact={true} component={Clients} />
      <Route path="/clients/vessels/:id" exact={true} component={Vessels} />
      <Route path="/products" exact={true} component={Products} />
      <Route path="/services" exact={true} component={Services} />
      <Route path="/solicitations" exact={true} component={Solicitations} />
      <Route path="/marinas" exact={true} component={Marinas} />
      <Route path="/settings" exact={true} component={Settings} />
      <Route path="/settings/subcategory/:id" exact={true} component={Subcategories} />
      <Route path="/requests" exact={true} component={Requests} />
    </Switch>
  </ BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
