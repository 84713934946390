import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import API from '../../api';
import Dash from '../../components/dash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

const ItemVessel = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleUpdateVessel = () => {

        setLoadingUpdate(true);

        API.patch('/vessels/update-vessel/'+props.vessel.id, qs.stringify({
            categories: categoriesVessel,
            description: descriptionVessel,
            subcategory: subcategoryVessel
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoadingUpdate(false);
            setOpen(false);
            props.handleGetVessels();

        })
        .catch(err => {

            setLoadingUpdate(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao salvar embarcação!');
            setOpenSnackbar(true);

        })

    }

    const [descriptionVessel, setDescriptionVessel] = useState(props.vessel.description);
    const [categoriesVessel, setCategoriesVessel] = useState(props.vessel.categories);
    const [subcategoryVessel, setSubcategoryVessel] = useState(props.vessel.categories);

    const [categories, setCategories] = useState([]);

    useEffect(() => {

        handleGetCategories();
        handleGetSubategoriesByCategory();

    }, [0])

    const handleGetSubategoriesByCategory = () => {

        API.get('/settings/subcategories-by-category/' + props.vessel.categories, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setSubcategories(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar subcategorias!');
            setOpenSnackbar(true);

        })

    }

    const handleGetCategories = () => {

        API.get('/settings/categories', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            let array = []
            
            res.data.forEach(element => {

                if(element.qtdSubcategory > 0){
                    element.subcategories = JSON.stringify(element.subcategories)
                }

                array.push(element)

            });

            setCategories(array);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar categorias!');
            setOpenSnackbar(true);

        })

    }

    const [subcategories, setSubcategories] = useState([]);

    return (
        <>

            <TableRow key={props.vessel.id}>
                <TableCell component="th" scope="row">
                    {props.vessel.nameCategories}
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.vessel.nameSubcategoria}
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.vessel.description}
                </TableCell>
                <TableCell align="right">
                    <Tooltip onClick={() => {setOpen(true)}} title="Editar" size="small">
                        <IconButton aria-label="editar">
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
    
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                        <Typography variant="h5">Edição de embarcação</Typography>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField 
                                    variant="outlined" 
                                    label="Categoria" 
                                    fullWidth 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    size="large"
                                    onChange={(e) => {
                                        setCategoriesVessel(e.target.value)
                                        let subcategories = e.target[e.target.selectedIndex].getAttribute('data-subcategories')
                                        if(subcategories.length > 0){
                                            setSubcategories(JSON.parse(subcategories))
                                        }else{
                                            setSubcategories([])
                                        }
                                    }}
                                    value={categoriesVessel}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <option value=""></option>
                                    {categories.map((categorie) => (
                                        <option data-subcategories={categorie.subcategories} value={categorie.id}>{categorie.name}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField 
                                    variant="outlined" 
                                    label="Subcategoria" 
                                    fullWidth 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    size="large"
                                    onChange={(e) => {setSubcategoryVessel(e.target.value)}}
                                    value={subcategoryVessel}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <option value=""></option>
                                    {subcategories.map((subcategory) => (
                                        <option value={subcategory.id}>{subcategory.name}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField value={descriptionVessel} onChange={(e) => {setDescriptionVessel(e.target.value)}} variant="outlined" label="Descrição do barco" fullWidth />
                            </Grid>
                        </Grid>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Button onClick={handleUpdateVessel} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                    {loadingUpdate ? 
                                        <CircularProgress size={24} style={{color: '#FFF'}} />
                                    :
                                        'Salvar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemVessel;