import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ItemProduct from './itemProduct';
import ItemProductAdmin from './itemProductAdmin';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, boat, solicitation, availability) {
    return { client, boat, solicitation, availability };
}
  
const rows = [
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'R$ 100,00')
    
];

function Products() {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    useEffect(() => {

        handleGetProducts();
        handlegetMarinas();

    }, [0])

    const [marinas, setMarinas] = useState([]);
    const [products, setProducts] = useState([]);

    const [typeUser, setTypeUser] = useState(localStorage.getItem('typeUser'));

    const handleGetProducts = (marina) => {

        let marinaSearch = "";

        if(marina){
            marinaSearch = marina;
        }

        API.get('/products?marina='+marinaSearch, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setProducts(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar produtos!');
            setOpenSnackbar(true);

        })

    }

    const handlegetMarinas = () => {

        API.get('/marinas', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setMarinas(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar marinas!');
            setOpenSnackbar(true);

        })

    }

    const [description, setDescription] = useState();
    const [value, setValue] = useState();
    const [loadingNew, setLoadingNew] = useState(false);

    const handleCreateProduct = () => {

        if(!description || !value){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/products/new-product', qs.stringify({
                description: description,
                value: value
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);

                setDescription('');
                setValue('');

                setOpen(false);

                handleGetProducts();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar o cliente!');
                setOpenSnackbar(true);

            })

        }

    }

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                {typeUser && typeUser === "superadmin" ?
                    <>

                        <Grid container spacing={3}>
                            <Grid item md={10} xs={0}></Grid>
                            <Grid item md={2} xs={12}>
                                <TextField 
                                    variant="outlined" 
                                    label="Marina" 
                                    fullWidth 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size="small"
                                    onChange={(e) => {
                                        handleGetProducts(e.target.value)
                                    }}
                                >
                                    <option value="">Todas</option>
                                    {marinas.map((marina) => (
                                        <option value={marina.id}>{marina.name}</option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <caption>Produtos</caption>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell align="left">Valor</TableCell>
                                                <TableCell align="left">Marina</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {products.map((product) => (
                                            <ItemProductAdmin handleGetProducts={handleGetProducts} product={product} />
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                    </>
                :
                    <>

                            <Grid container spacing={3}>
                                <Grid item md={10} xs={0}></Grid>
                                <Grid item md={2} xs={12}>
                                    <Button onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}} fullWidth>Criar produto</Button>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="caption table">
                                            <caption>Produtos</caption>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Descrição</TableCell>
                                                    <TableCell align="left">Valor</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {products.map((product) => (
                                                <ItemProduct handleGetProducts={handleGetProducts} product={product} />
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                    </>
                }
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Cadastro de produtos</Typography>

                            <br></br>

                            <Typography variant="h6" style={{color: '#707072'}}>Dados</Typography>
                            
                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={9} xs={12}>
                                    <TextField value={description} onChange={(e) => {setDescription(e.target.value)}} variant="outlined" label="Descrição" fullWidth />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField value={value} onChange={(e) => {setValue(e.target.value)}} variant="outlined" label="Valor" fullWidth type="number" />
                                </Grid>
                            </Grid>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={handleCreateProduct} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                        {loadingNew ? 
                                            <CircularProgress size={24} style={{color: '#FFF'}} />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>

                <Modal
                    className={classes.modal}
                    open={openDelete}
                    onClose={() => {setOpenDelete(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={openDelete}>
                        <div className={classes.paper} style={{display: 'flex', alignItems: 'center'}}>

                            <div style={{width: '100%'}}>
                                <Typography variant="h5" style={{textAlign: 'center'}}>Deleter serviço</Typography>

                                <br></br>

                                <Typography variant="h6" style={{color: '#707072', textAlign: 'center'}}>Tem certeza?</Typography>
                                
                                <br></br>
                                <br></br>

                                <Grid container spacing={3}>
                                    <Grid item md={3} xs={12}></Grid>
                                    <Grid item md={3} xs={12}>
                                        <Button onClick={() => {setOpenDelete(false)}} fullWidth size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF'}}>Cancelar</Button>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <Button fullWidth size="large" style={{textTransform: 'none', border: '1px solid #3282A5', color: '#3282A5'}}>Deletar</Button>
                                    </Grid>
                                    <Grid item md={3} xs={12}></Grid>
                                </Grid>
                            </div>
                            
                        </div>
                    </Fade>
                </Modal>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
        
            </div>

        </div>
    );

}

export default Products;