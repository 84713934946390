import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ItemClientAdmin = props => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <>

            <TableRow key={props.client.index}>
                <TableCell component="th" scope="props.client">
                    {props.client.name}
                </TableCell>
                <TableCell align="left">{props.client.email}</TableCell>
                <TableCell align="left">{props.client.cellphone}</TableCell>
                <TableCell align="left">{props.client.amountVessels}</TableCell>
                <TableCell align="left">{props.client.nameMarina}</TableCell>
                <TableCell align="left">{moment(props.client.created_at).format('DD/MM/YYYY')}</TableCell>
            </TableRow>

        </>
    );

}

export default ItemClientAdmin;