import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import qs from 'qs';

import API from '../api';

/** Material UI */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Container, Button, TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

/** Assets */
import whiteLogo from '../assets/img/white_logo.png';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    img: {
        marginRight: 'auto', 
        marginLeft: 'auto', 
        display: 'block',
        width: '26%',
        '@media (max-width: 600px)': {
            width: '40%'
        }
    }
}));

function Login() {

    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleLogin = () => {

        setLoading(true);

        if(!email || !password){

            setLoading(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            API.post('/user/login', qs.stringify({
                email: email,
                password: password
              }), {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Accept': '*/*'
                }
              })
              .then(res => {
                
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('typeUser', res.data.type);
                history.push('/home');
          
              })
              .catch(err => {

                setLoading(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao realizar o login');
                setOpenSnackbar(true);

              })

        }

    }

    return (
        <div className={classes.root}>

            <Container>
                <Grid spacing={3} container>
                    <Grid item md={3} xs={0}></Grid>
                    <Grid item md={6} xs={12}>
                        
                            <img src={whiteLogo} className={classes.img} />
                            <br></br><br></br>
                            <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} variant="outlined" label="E-mail" fullWidth/>
                            <br></br><br></br>
                            <TextField value={password} onChange={(e) => {setPassword(e.target.value)}} variant="outlined" label="Senha" type="password" fullWidth />
                            <br></br><br></br>
                            <Button onClick={handleLogin} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF'}} fullWidth>
                                {loading ? 
                                    <CircularProgress size={24} style={{color: '#FFF'}} />
                                :
                                    'Entrar'
                                }
                            </Button>
                        
                    </Grid>
                    <Grid item md={3} xs={0}></Grid>
                </Grid>
            </Container>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
      
        </div>
    );

}

export default Login;