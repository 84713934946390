import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

const ItemClient = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [name, setName] = useState(props.client.name);
    const [email, setEmail] = useState(props.client.email);
    const [cellphone, setCellphone] = useState(props.client.cellphone);
    const [rg, setRg] = useState(props.client.rg);
    const [cpf, setCpf] = useState(props.client.cpf);
    const [birthDate, setBirthDate] = useState(props.client.birth_date);
    const [cep, setCep] = useState(props.client.cep);
    const [address, setAddress] = useState(props.client.address);
    const [number, setNumber] = useState(props.client.number);
    const [neighborhood, setNeighborhood] = useState(props.client.neighborhood);
    const [complement, setComplement] = useState(props.client.complement);
    const [city, setCity] = useState(props.client.city);
    const [state, setState] = useState(props.client.state);
    const [note, setNote] = useState(props.client.note);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleUpdateClient = () => {

        setLoadingUpdate(true);

        API.patch('/clients/update-client/'+props.client.id, qs.stringify({
            name: name,
                email: email,
                cellphone: cellphone,
                rg: rg,
                cpf: cpf,
                birthDate: birthDate,
                cep: cep,
                address: address,
                number: number,
                neighborhood: neighborhood,
                complement: complement,
                city: city,
                state: state,
                note: note
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoadingUpdate(false);
            setOpen(false);
            props.handleGetClients();

        })
        .catch(err => {

            setLoadingUpdate(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao salvar cliente!');
            setOpenSnackbar(true);

        })

    }

    const handleGetEnderecoByCep = () => {

        axios.get('https://viacep.com.br/ws/'+cep+'/json/',{
            params: {},
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setAddress(res.data.logradouro);
            setNeighborhood(res.data.bairro);
            setCity(res.data.localidade);
            setState(res.data.uf);
        })
        .catch(res => {})

    }

    return (
        <>

            <TableRow key={props.client.index}>
                <TableCell component="th" scope="props.client">
                    {props.client.name}
                </TableCell>
                <TableCell align="left">{props.client.email}</TableCell>
                <TableCell align="left">{props.client.cellphone}</TableCell>
                <TableCell align="left">{props.client.amountVessels} <Link to={`/clients/vessels/`+props.client.id} style={{textDecoration: 'none'}}><span style={{color: '#3282A5', cursor: 'pointer'}}>editar</span></Link></TableCell>
                <TableCell align="left">{props.client.nameMarina}</TableCell>
                {/*<TableCell align="left">0 <span style={{color: '#3282A5', cursor: 'pointer'}}>gerenciar</span></TableCell>*/}
                <TableCell align="left">{moment(props.client.created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="right">
                    <Tooltip onClick={() => {setOpen(true)}} title="Editar" size="small">
                        <IconButton aria-label="editar">
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
    
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                        <Typography variant="h5">Editar clientes</Typography>

                        <br></br>

                        <Typography variant="h6" style={{color: '#707072'}}>Dados</Typography>
                        
                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField value={name} onChange={(e) => {setName(e.target.value)}} variant="outlined" label="Nome completo" fullWidth />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} variant="outlined" label="E-mail" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <InputMask
                                    mask="(99) 99999-9999"
                                    value={cellphone} 
                                    onChange={(e) => {setCellphone(e.target.value)}}
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField variant="outlined" label="Celular" fullWidth />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField value={rg} onChange={(e) => {setRg(e.target.value)}} variant="outlined" label="RG" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <InputMask
                                    mask="999.999.999-99"
                                    value={cpf} 
                                    onChange={(e) => {setCpf(e.target.value)}}
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField variant="outlined" label="CPF" fullWidth />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <InputMask
                                    mask="99/99/9999"
                                    value={birthDate} 
                                    onChange={(e) => {setBirthDate(e.target.value)}}
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField variant="outlined" label="Nascimento" fullWidth />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <InputMask
                                    mask="99999-999"
                                    value={cep} 
                                    onChange={(e) => {setCep(e.target.value)}}
                                    onBlur={handleGetEnderecoByCep}
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField variant="outlined" label="CEP" fullWidth />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <TextField InputLabelProps={{shrink: address ? true : false}} value={address} onChange={(e) => {setAddress(e.target.value)}} variant="outlined" label="Endereço" fullWidth />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <InputMask
                                    mask="99999999"
                                    value={number} 
                                    onChange={(e) => {setNumber(e.target.value)}} 
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField variant="outlined" label="Número" fullWidth />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: neighborhood ? true : false}} value={neighborhood} onChange={(e) => {setNeighborhood(e.target.value)}} variant="outlined" label="Bairro" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField value={complement} onChange={(e) => {setComplement(e.target.value)}} variant="outlined" label="Complemento" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: city ? true : false}} value={city} onChange={(e) => {setCity(e.target.value)}} variant="outlined" label="Cidade" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: state ? true : false}} value={state} onChange={(e) => {setState(e.target.value)}} variant="outlined" label="Estado" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField multiline rows={4} InputLabelProps={{shrink: note ? true : false}} value={note} onChange={(e) => {setNote(e.target.value)}} variant="outlined" label="Observação" fullWidth />
                            </Grid>
                        </Grid>

                        {/*<br></br>

                        <Typography variant="h6" style={{color: '#707072'}}>Barcos</Typography>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={3} xs={12}>
                                <TextField 
                                    variant="outlined" 
                                    label="Categoria" 
                                    fullWidth 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    size="large"
                                >
                                    <option value=""></option>
                                    <option value="marina_1">Categoria 1</option>
                                    <option value="marina_2">Categoria 2</option>
                                    <option value="marina_3">Categoria 3</option>
                                </TextField>
                            </Grid>
                            <Grid item md={9} xs={12}>
                                <TextField variant="outlined" label="Descrição do barco" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography variant="body1" style={{color: '#3282A5', marginTop: '0px'}}>Adicionar barco</Typography>
                            </Grid>
                        </Grid>*/}

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Button onClick={handleUpdateClient} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                    {loadingUpdate ? 
                                        <CircularProgress size={24} style={{color: '#FFF'}} />
                                    :
                                        'Salvar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemClient;