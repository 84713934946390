import axios from 'axios';

let url = "";

if (window.location.hostname === 'localhost') {
    url = 'http://localhost:8000';
} else {
    url = 'https://api.myboat.appandwebstudio.com.br';
}

export default axios.create({
  baseURL: url
});