import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ItemServiceAdmin = props => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <>

            <TableRow key={props.service.index}>
                <TableCell component="th" scope="props.client">
                    {props.service.description}
                </TableCell>
                <TableCell align="left">{parseFloat(props.service.value).toLocaleString('pt-BR',{style: 'currency', currency:'BRL'})}</TableCell>
                <TableCell align="left">
                    {props.service.nameMarina}
                </TableCell>
            </TableRow>

        </>
    );

}

export default ItemServiceAdmin;