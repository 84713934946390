import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import API from '../../api';
import Dash from '../../components/dash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ItemStatus from './ItemStatus';
import ItemCategories from './ItemCategories';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(id, status, slug) {
    return { id, status, slug };
}
  
const rows = [
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
    createData('1', 'Em preparação', 'em-preparacao'),
];

const rowsCategory = [
    createData('1', 'Barco'),
    createData('1', 'Lancha'),
];

function Settings() {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const [value, setValue] = useState(0);

    const [nameCategories, setNameCategories] = useState();
    const [nameStatus, setNameStatus] = useState();
    const [colorStatus, setColorStatus] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCreateStatus = () => {

        if(!nameStatus){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha o status');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/settings/new-status', qs.stringify({
                name: nameStatus,
                color: colorStatus
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);
                setNameStatus('');
                setOpen(false);
                handleGetStatus();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar status!');
                setOpenSnackbar(true);

            })

        }

    }

    const handleCreateCategories = () => {

        if(!nameCategories){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha a categoria');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/settings/new-categories', qs.stringify({
                name: nameCategories
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);
                setNameStatus('');
                setOpen(false);
                handleGetCategories();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar categoria!');
                setOpenSnackbar(true);

            })

        }

    }

    const [statuss, setStatuss] = useState([]);
    const [categories, setCategories] = useState([]);
    
    const handleGetStatus = () => {

        API.get('/settings/status', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setStatuss(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar status!');
            setOpenSnackbar(true);

        })

    }

    const handleGetCategories = () => {

        API.get('/settings/categories', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setCategories(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar categorias!');
            setOpenSnackbar(true);

        })

    }

    useEffect(() => {

        handleGetStatus();
        handleGetCategories();

    }, [0])

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab style={{textTransform: 'none'}} label="Status solicitações" {...a11yProps(0)} />
                    <Tab style={{textTransform: 'none'}} label="Categorias embarcações" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    
                    <Grid container spacing={3}>
                        <Grid item md={2} xs={12}>
                            <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Novo status</Button>
                        </Grid>
                        <Grid item md={10} xs={12}></Grid>
                        <Grid item md={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="caption table">
                                    <caption>Status</caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Color</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {statuss.map((status) => (
                                            <ItemStatus status={status} handleGetStatus={handleGetStatus} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
            
                    <Modal
                        className={classes.modal}
                        open={open}
                        onClose={() => {setOpen(false)}}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>

                                <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                                <Typography variant="h5">Novo status</Typography>

                                <br></br>

                                <Grid container spacing={3}>
                                    <Grid item md={8} xs={12}>
                                        <TextField value={nameStatus} onChange={(e) => {setNameStatus(e.target.value)}} variant="outlined" label="Nome" fullWidth />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField type="color" value={colorStatus} onChange={(e) => {setColorStatus(e.target.value)}} variant="outlined" label="Cor" fullWidth />
                                    </Grid>
                                    {/*<Grid item md={3} xs={12}>
                                        <TextField variant="outlined" label="slug" fullWidth disabled />
                                    </Grid>*/}
                                </Grid>

                                <br></br>

                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <Button onClick={handleCreateStatus} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                            {loadingNew ? 
                                                <CircularProgress size={24} style={{color: '#FFF'}} />
                                            :
                                                'Salvar'
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </Fade>
                    </Modal>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    
                    <Grid container spacing={3}>
                        <Grid item md={2} xs={12}>
                            <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Nova categoria</Button>
                        </Grid>
                        <Grid item md={10} xs={12}></Grid>
                        <Grid item md={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="caption table">
                                    <caption>Categorias</caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Subcategorias</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {categories.map((categorie) => (
                                            <ItemCategories categorie={categorie} handleGetCategories={handleGetCategories} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
            
                    <Modal
                        className={classes.modal}
                        open={open}
                        onClose={() => {setOpen(false)}}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>

                                <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                                <Typography variant="h5">Cadastro de categoria</Typography>

                                <br></br>

                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField value={nameCategories} onChange={(e) => {setNameCategories(e.target.value)}} variant="outlined" label="Nome" fullWidth />
                                    </Grid>
                                </Grid>

                                <br></br>

                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <Button onClick={handleCreateCategories} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                            {loadingNew ? 
                                                <CircularProgress size={24} style={{color: '#FFF'}} />
                                            :
                                                'Salvar'
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </Fade>
                    </Modal>

                </TabPanel>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
        
            </div>

        </div>
    );

}

export default Settings;