import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
  
/** Assets */
import whiteLogo from '../assets/img/white_logo.png';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';

import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DirectionsBoatOutlinedIcon from '@material-ui/icons/DirectionsBoatOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      display: 'none'
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      //display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      display: 'none'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
}));

const Dash = props => {

    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();

    const [typeUser, setTypeUser] = useState(localStorage.getItem('typeUser'));

    const [open, setOpen] = React.useState(!isMobile ? true : false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        if(!localStorage.getItem('token')){
            history.push('/');
        }

    })

    const handleExit = () => {

        localStorage.clear();
        history.push('/');

    }

    return (
        <div className={classes.root}>

            {!isMobile ?
                <>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                        })}
                    >
                        <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            Persistent drawer
                        </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                        paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader} style={{padding: '20px'}}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? 
                                    <>
                                        <ChevronLeftIcon /> 
                                    </>
                                : 
                                    <>
                                        <ChevronRightIcon />
                                    </>
                                }
                            </IconButton>
                        </div>
                        <Divider />
                        <List style={{minHeight: '99vh'}}>
                            <ListItem>
                                <Link to="/home" style={{textDecoration: 'none', color: '#000'}}>
                                    <img alt="Myboat" src={whiteLogo} style={{width: '50px'}} />
                                </Link>
                            </ListItem>
                            <br></br>
                            <Link to="/home" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/home" ? '#3282A5' : ''}}><HomeOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/home" ? '#3282A5' : ''}} primary="Início" />
                                </ListItem>
                            </Link>
                            <Link to="/clients" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/clients" ? '#3282A5' : ''}}><PeopleAltOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/clients" ? '#3282A5' : ''}} primary="Clientes" />
                                </ListItem>
                            </Link>
                            <Link to="/products" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/products" ? '#3282A5' : ''}}><ShoppingCartOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/products" ? '#3282A5' : ''}} primary="Produtos" />
                                </ListItem>
                            </Link>
                            <Link to="/services" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/services" ? '#3282A5' : ''}}><ShoppingCartOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/services" ? '#3282A5' : ''}} primary="Serviços" />
                                </ListItem>
                            </Link>
                            {/*<Link to="/solicitations" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/solicitations" ? '#3282A5' : ''}}><DescriptionOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/solicitations" ? '#3282A5' : ''}} primary="Solicitações (F)" />
                                </ListItem>
                            </Link>
                            <Link to="/requests" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/requests" ? '#3282A5' : ''}}><DescriptionOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/requests" ? '#3282A5' : ''}} primary="Pedidos (F)" />
                                </ListItem>
                            </Link>*/}
                            {typeUser && typeUser === "superadmin" && (
                                <>
                                    <Link to="/marinas" style={{textDecoration: 'none', color: '#000'}}>
                                        <ListItem button>
                                            <ListItemIcon style={{color: location.pathname === "/marinas" ? '#3282A5' : ''}}><DirectionsBoatOutlinedIcon /></ListItemIcon>
                                            <ListItemText style={{color: location.pathname === "/marinas" ? '#3282A5' : ''}} primary="Marinas" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/settings" style={{textDecoration: 'none', color: '#000'}}>
                                        <ListItem button>
                                            <ListItemIcon style={{color: location.pathname === "/settings" ? '#3282A5' : ''}}><SettingsOutlinedIcon /></ListItemIcon>
                                            <ListItemText style={{color: location.pathname === "/settings" ? '#3282A5' : ''}} primary="Configurações" />
                                        </ListItem>
                                    </Link>
                                </>
                            )}
                            <ListItem onClick={handleExit} button style={{position: 'absolute', bottom: 0}}>
                                <ListItemIcon><ExitToAppOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Sair" />
                            </ListItem>
                        </List>
                    </Drawer>
                    {/*<main
                        className={clsx(classes.content, {
                        [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        <Typography paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                            facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                            gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                            donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                            adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                            Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                            imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                            arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                            donec massa sapien faucibus et molestie ac.
                        </Typography>
                        <Typography paragraph>
                            Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
                            facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
                            tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
                            consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
                            vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In
                            hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et
                            tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin
                            nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas
                            accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
                        </Typography>
                    </main>*/}
                </>
            :
                <>

                    <Drawer anchor="left" open={open} onClose={() => {setOpen(false)}}>
                        <List style={{minHeight: '99vh', width: '60vw'}}>
                            <ListItem>
                                <Link to="/home" style={{textDecoration: 'none', color: '#000'}}>
                                    <img alt="Myboat" src={whiteLogo} style={{width: '50px'}} />
                                </Link>
                            </ListItem>
                            <br></br>
                            <Link to="/home" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/home" ? '#3282A5' : ''}}><HomeOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/home" ? '#3282A5' : ''}} primary="Início" />
                                </ListItem>
                            </Link>
                            <Link to="/clients" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/clients" ? '#3282A5' : ''}}><PeopleAltOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/clients" ? '#3282A5' : ''}} primary="Clientes" />
                                </ListItem>
                            </Link>
                            <Link to="/products" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/products" ? '#3282A5' : ''}}><ShoppingCartOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/products" ? '#3282A5' : ''}} primary="Produtos" />
                                </ListItem>
                            </Link>
                            <Link to="/services" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/services" ? '#3282A5' : ''}}><ShoppingCartOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/services" ? '#3282A5' : ''}} primary="Serviços" />
                                </ListItem>
                            </Link>
                            {/*<Link to="/solicitations" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/solicitations" ? '#3282A5' : ''}}><DescriptionOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/solicitations" ? '#3282A5' : ''}} primary="Solicitações" />
                                </ListItem>
                            </Link>
                            <Link to="/requests" style={{textDecoration: 'none', color: '#000'}}>
                                <ListItem button>
                                    <ListItemIcon style={{color: location.pathname === "/requests" ? '#3282A5' : ''}}><DescriptionOutlinedIcon /></ListItemIcon>
                                    <ListItemText style={{color: location.pathname === "/requests" ? '#3282A5' : ''}} primary="Pedidos" />
                                </ListItem>
                            </Link>*/}
                            {typeUser && typeUser === "superadmin" && (
                                <>
                                    <Link to="/marinas" style={{textDecoration: 'none', color: '#000'}}>
                                        <ListItem button>
                                            <ListItemIcon style={{color: location.pathname === "/marinas" ? '#3282A5' : ''}}><DirectionsBoatOutlinedIcon /></ListItemIcon>
                                            <ListItemText style={{color: location.pathname === "/marinas" ? '#3282A5' : ''}} primary="Marinas" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/settings" style={{textDecoration: 'none', color: '#000'}}>
                                        <ListItem button>
                                            <ListItemIcon style={{color: location.pathname === "/settings" ? '#3282A5' : ''}}><SettingsOutlinedIcon /></ListItemIcon>
                                            <ListItemText style={{color: location.pathname === "/settings" ? '#3282A5' : ''}} primary="Configurações" />
                                        </ListItem>
                                    </Link>
                                </>
                            )}
                            <ListItem onClick={handleExit} button style={{position: 'absolute', bottom: 0}}>
                                <ListItemIcon><ExitToAppOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Sair" />
                            </ListItem>
                        </List>
                    </Drawer>

                    <Fab onClick={() => {setOpen(true)}} color="primary" aria-label="menu" style={{position: 'fixed', bottom: 10, left: 10, backgroundColor: 'rgb(50, 130, 165)'}}>
                        <MenuOutlinedIcon />
                    </Fab>

                </>
            }
      
        </div>
    );

}

export default Dash;