import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

const ItemMarina = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState(props.marina.name);
    const [city, setCity] = useState(props.marina.city);
    const [state, setState] = useState(props.marina.state);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleUpdateMarina = () => {

        setLoadingUpdate(true);

        API.patch('/marinas/update-marina/'+props.marina.id, qs.stringify({
            name: name,
            city: city,
            state: state
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoadingUpdate(false);
            setOpen(false);
            props.handleGetMarinas();

        })
        .catch(err => {

            setLoadingUpdate(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao salvar marina!');
            setOpenSnackbar(true);

        })

    }

    return (
        <>

            <TableRow key={props.marina.index}>
                <TableCell component="th" scope="row">
                    {props.marina.name}
                </TableCell>
                <TableCell align="left">{props.marina.city}</TableCell>
                <TableCell align="left">{props.marina.state}</TableCell>
                <TableCell align="right">
                    <Tooltip onClick={() => setOpen(true)} title="Editar" size="small">
                        <IconButton aria-label="editar">
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Deletar" size="small">
                        <IconButton aria-label="deletar">
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>*/}
                </TableCell>
            </TableRow>
    
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                        <Typography variant="h5">Editar marina</Typography>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField value={name} onChange={(e) => {setName(e.target.value)}} variant="outlined" label="Nome" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField value={city} onChange={(e) => {setCity(e.target.value)}} variant="outlined" label="Cidade" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField value={state} onChange={(e) => {setState(e.target.value)}} variant="outlined" label="Estado" fullWidth />
                            </Grid>
                        </Grid>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Button onClick={handleUpdateMarina} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                    {loadingUpdate ? 
                                        <CircularProgress size={24} style={{color: '#FFF'}} />
                                    :
                                        'Salvar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemMarina;