import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import API from '../../api';
import Dash from '../../components/dash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

const ItemStatus = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [nameStatus, setNameStatus] = useState(props.status.name);
    const [colorStatus, setColorStatus] = useState(props.status.color);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleUpdateStatus = () => {

        setLoadingUpdate(true);

        API.patch('/settings/update-status/'+props.status.id, qs.stringify({
            name: nameStatus,
            color: colorStatus
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setLoadingUpdate(false);
            setOpen(false);
            props.handleGetStatus();

        })
        .catch(err => {

            setLoadingUpdate(false);
            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao salvar status!');
            setOpenSnackbar(true);

        })

    }

    return (
        <>

            <TableRow key={props.status.id}>
                <TableCell component="th" scope="row">
                    {props.status.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.status.name}
                </TableCell>
                <TableCell component="th" scope="row">
                    <div style={{backgroundColor: `${props.status.color}`, width: '20px', height: '20px', borderRadius: '50%'}}></div>
                </TableCell>
                <TableCell align="right">
                    <Tooltip onClick={() => {setOpen(true)}} title="Editar" size="small">
                        <IconButton aria-label="editar">
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
    
            <Modal
                className={classes.modal}
                open={open}
                onClose={() => {setOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                        <Typography variant="h5">Edição de status</Typography>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={8} xs={12}>
                                <TextField value={nameStatus} onChange={(e) => {setNameStatus(e.target.value)}} variant="outlined" label="Nome" fullWidth />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField type="color" value={colorStatus} onChange={(e) => {setColorStatus(e.target.value)}} variant="outlined" label="Cor" fullWidth />
                            </Grid>
                            {/*<Grid item md={3} xs={12}>
                                <TextField variant="outlined" label="slug" fullWidth disabled />
                            </Grid>*/}
                        </Grid>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Button onClick={handleUpdateStatus} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                    {loadingUpdate ? 
                                        <CircularProgress size={24} style={{color: '#FFF'}} />
                                    :
                                        'Salvar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemStatus;