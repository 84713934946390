import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import API from '../../api';
import Dash from '../../components/dash';
import qs from 'qs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputMask from 'react-input-mask';
import axios from 'axios';
import ItemVessel from './ItemVessel';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, boat, solicitation, availability) {
    return { client, boat, solicitation, availability };
}
  
const rows = [
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00'),
    createData('Fulano de Tal', '(16) 99225-7677', '10', '10/07/2021 às 21:00')
    
];

const Vessels = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [cellphone, setCellphone] = useState();
    const [rg, setRg] = useState();
    const [cpf, setCpf] = useState();
    const [birthDate, setBirthDate] = useState();
    const [cep, setCep] = useState();
    const [address, setAddress] = useState();
    const [number, setNumber] = useState();
    const [neighborhood, setNeighborhood] = useState();
    const [complement, setComplement] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();

    const [loading, setLoading] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const handleCreateVessel = () => {

        if(!categoriesVessel || !descriptionVessel || !subcategoryVessel){

            setSnackbarSeverity('error');
            setSnackbarMessage('Preencha todos os dados');
            setOpenSnackbar(true);

        }else{

            setLoadingNew(true);

            API.post('/vessels/new-vessel', qs.stringify({
                user: props.match.params.id,
                description: descriptionVessel,
                categories: categoriesVessel,
                subcategory: subcategoryVessel
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                
                setLoadingNew(false);

                setCategoriesVessel('');
                setDescriptionVessel('');

                setOpen(false);

                handleGetVessels();

            })
            .catch(err => {

                setLoadingNew(false);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erro ao salvar a embarcação!');
                setOpenSnackbar(true);

            })

        }

    }

    const [categories, setCategories] = useState([]);
    const [nameClient, setNameClient] = useState();
    const [vessels, setVessels] = useState([]);

    useEffect(() => {

        handleGetCategories();
        handleGetDetailClient();
        handleGetVessels();

    }, [0])

    const handleGetVessels = () => {

        API.get('/vessels/by-client/'+props.match.params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setVessels(res.data);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar as embarcações!');
            setOpenSnackbar(true);

        })

    }

    const handleGetDetailClient = () => {

        API.get('/clients/view/'+props.match.params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            
            setNameClient(res.data.name);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar detalhes do cliente!');
            setOpenSnackbar(true);

        })

    }

    const handleGetCategories = () => {

        API.get('/settings/categories', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {

            let array = []
            
            res.data.forEach(element => {

                if(element.qtdSubcategory > 0){
                    element.subcategories = JSON.stringify(element.subcategories)
                }

                array.push(element)

            });

            setCategories(array);

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao buscar categorias!');
            setOpenSnackbar(true);

        })

    }

    const [descriptionVessel, setDescriptionVessel] = useState();
    const [categoriesVessel, setCategoriesVessel] = useState();

    const [subcategories, setSubcategories] = useState([])
    const [subcategoryVessel, setSubcategoryVessel] = useState()

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Grid container spacing={3} style={{display: 'flex', alignItems: 'center'}}>
                    <Grid item md={10} xs={12}>
                        <Typography variant="h5">Cliente: {nameClient}</Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Atribuir embarcação</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table">
                                <caption>Embarcações</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Categoria</TableCell>
                                        <TableCell align="left">Subcategoria</TableCell>
                                        <TableCell align="left">Descrição</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vessels.map((vessel) => (
                                        <ItemVessel handleGetVessels={handleGetVessels} vessel={vessel} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Cadastro de embarção</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Categoria" 
                                        fullWidth 
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        size="large"
                                        onChange={(e) => {
                                            setCategoriesVessel(e.target.value)
                                            let subcategories = e.target[e.target.selectedIndex].getAttribute('data-subcategories')
                                            if(subcategories.length > 0){
                                                setSubcategories(JSON.parse(subcategories))
                                            }else{
                                                setSubcategories([])
                                            }
                                        }}
                                    >
                                        <option value=""></option>
                                        {categories.map((categorie) => (
                                            <option data-subcategories={categorie.subcategories} value={categorie.id}>{categorie.name}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Subcategoria" 
                                        fullWidth 
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        size="large"
                                        onChange={(e) => {setSubcategoryVessel(e.target.value)}}
                                        disabled={subcategories <= 0}
                                    >
                                        <option value=""></option>
                                        {subcategories.map((subcategory) => (
                                            <option value={subcategory.id}>{subcategory.name}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField value={descriptionVessel} onChange={(e) => {setDescriptionVessel(e.target.value)}} variant="outlined" label="Descrição do barco" fullWidth />
                                </Grid>
                            </Grid>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={handleCreateVessel} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>
                                        {loadingNew ? 
                                            <CircularProgress size={24} style={{color: '#FFF'}} />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>

                <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                    <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </div>

        </div>
    );

}

export default Vessels;