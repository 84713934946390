import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, Modal, Backdrop, Fade, TextField, Tooltip, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import Dash from '../components/dash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
        padding: '20px',
        width: '100%'
    },
    table: {
        minWidth: 650
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        borderRadius: '5px',
        height: '70vh',
        overflowY: 'auto',
        width: '70vw',
        position: 'relative'
    }
}));

function createData(client, boat, status) {
    return { client, boat, status };
}
  
const rows = [
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação'),
    createData('Descrição de serviço teste cadastrado na plataforma', 'Lancha Nomar 490 Sport', 'Em separação')
    
];

function Solicitations() {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <div className={classes.root}>

            <Dash />

            <div className={classes.content}>

                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}></Grid>
                    <Grid item md={2} xs={12}>
                        <TextField 
                            variant="outlined" 
                            label="Marina" 
                            fullWidth 
                            select
                            SelectProps={{
                                native: true,
                            }}
                            size="small"
                        >
                            <option value=""></option>
                            <option value="marina_1">Marina 1</option>
                            <option value="marina_2">Marina 2</option>
                            <option value="marina_3">Marina 3</option>
                        </TextField>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button fullWidth onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px', float: 'right'}}>Nova solicitação</Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table">
                                <caption>Solicitações</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell align="left">Barco</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Marina</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.index}>
                                        <TableCell component="th" scope="row">
                                            {row.client} <span style={{color: '#3282A5', cursor: 'pointer', fontSize: '14px'}}>histórico</span>
                                        </TableCell>
                                        <TableCell align="left">{row.boat}</TableCell>
                                        <TableCell align="left">
                                            <TextField 
                                                variant="outlined" 
                                                label="Status" 
                                                //fullWidth 
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                size="small"
                                                style={{width: '70%'}}
                                            >
                                                <option value="em_separacao">Em separação</option>
                                                <option value="status">Novo status</option>
                                            </TextField>
                                        </TableCell>
                                        <TableCell align="left">Marina 1</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Editar">
                                                <IconButton aria-label="editar" size="small">
                                                    <EditOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
        
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={() => {setOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>

                            <CloseOutlinedIcon onClick={() => {setOpen(false)}} style={{position: 'absolute', right: '25px', top: '25px', cursor: 'pointer'}} />
                            <Typography variant="h5">Inserir solicitação</Typography>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <TextField variant="outlined" label="Cliente" fullWidth />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField variant="outlined" label="Barco" fullWidth />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Status" 
                                        fullWidth 
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        <option value="em_separacao">Em separação</option>
                                    </TextField>
                                </Grid>
                            </Grid>

                            <br></br>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={() => {setOpen(true)}} size="large" style={{textTransform: 'none', backgroundColor: '#3282A5', color: '#FFF', paddingRight: '30px', paddingLeft: '30px'}}>Salvar</Button>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Fade>
                </Modal>
        
            </div>

        </div>
    );

}

export default Solicitations;