import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    }
}));


const ItemOrder = props => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <>

            <TableRow>
                <TableCell component="th" scope="row">{props.order.client}</TableCell>
                <TableCell align="left">{parseFloat(props.order.total_value).toLocaleString('pt-BR',{style: 'currency', currency:'BRL'})}</TableCell>
                {props.typeUser && props.typeUser === "superadmin" && ( <TableCell align="left">{props.order.marina}</TableCell> )}
                <TableCell align="left">{moment(props.order.created_at).format('DD/MM/YYYY HH:m:ss')}</TableCell>
                <TableCell align="left">{moment(props.order.updated_at).format('DD/MM/YYYY HH:m:ss')}</TableCell>
            </TableRow>

        </>
    );

}

export default ItemOrder;