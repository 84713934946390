import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, Paper, Grid, Container, TextField } from '@material-ui/core';
import moment from 'moment';
import qs from 'qs';
import API from '../../api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    }
}));


const ItemSolicitation = props => {

    const classes = useStyles();
    const theme = useTheme();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState();

    const [statusSolicitation, setStatusSolicitation] = useState(props.solicitation.status)

    const handleChangeStatus = (status, id) => {

        API.patch(`/solicitations/update-solicitation/${id}`, qs.stringify({
            status: status
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {

            setStatusSolicitation(status)
            setSnackbarSeverity('success');
            setSnackbarMessage('Status alterado');
            setOpenSnackbar(true);
            props.handleGetSolicitations()

        })
        .catch(err => {

            setSnackbarSeverity('error');
            setSnackbarMessage('Erro ao alterar status');
            setOpenSnackbar(true);

        })

    }

    return (
        <>

            <TableRow>
                <TableCell component="th" scope="row">
                    {props.solicitation.client}
                </TableCell>
                <TableCell align="left">{props.solicitation.nameVessels}</TableCell>
                <TableCell align="left">
                    <TextField 
                        variant="outlined" 
                        label="Status" 
                        //fullWidth 
                        select
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        style={{width: '70%'}}
                        value={statusSolicitation}
                        onChange={(e) => {handleChangeStatus(e.target.value, props.solicitation.id)}}
                    >
                        {props.statuss.map((status) => (
                            <option key={status.index} value={status.id}>{status.name}</option>
                        ))}
                    </TextField>
                </TableCell>
                <TableCell align="left">{moment(props.solicitation.created_at).format('DD/MM/YYYY HH:m:ss')}</TableCell>
                <TableCell align="left">{moment(props.solicitation.updated_at).format('DD/MM/YYYY HH:m:ss')}</TableCell>
            </TableRow>

            <Snackbar open={openSnackbar} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );

}

export default ItemSolicitation;